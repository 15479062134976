<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/wisesight.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                {{price}}
                <!-- Earlybird 3,900 บาท (1 - 18 ส.ค. 2562) <br>
                Achieve Member 2,900 บาท
                <div class="text-right">
                  จากราคาเต็ม {{ price }}
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> register now </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> register now </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow " >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img> -->
                    <v-icon>{{ salebox.icon }}</v-icon>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <!-- <br/> -->
              <!-- <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <v-icon>fas fa-chalkboard-teacher</v-icon>
                </v-col>
                <v-col >
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                </v-col>
              </v-row> -->
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px ">
              <div class="pd-24px text-content">
                Achieve แคร์คุณมากกว่าหน้าจอ พาคุณไปเยี่ยมชม Data Driven Organization กับกิจกรรม
                [Pathway to Achieve] : Achieve x Wisesight
                <br/><br/>

                Wisesight เป็นผู้ให้บริการด้านการวิเคราะห์ข้อมูลโซเชียลมีเดีย
                อันดับหนึ่งในประเทศไทยที่นำข้อมูลบนโซเชียล
                เช่น เฟซบุ๊ก อินสตราแกรม ทวิตเตอร์ ต่าง ๆ มาวิเคราะห์
                สังเคราะห์และปลดล็อคศักยภาพของข้อมูลดิบจนกลายเป็นข้อมูลที่มีคุณค่า
                สร้างประโยชน์ให้กับแบรนด์ในทุก ๆ กลุ่มอุตสาหกรรม
                <br/><br/>

                ทุกวันนี้คนเราใช้ Social Media กันเกือบทุกเวลา ทำให้เกิด “ข้อมูล” ขึ้นมาเยอะมาก
                ข้อมูลกว่า 5.3 พันล้านข้อความที่ถูกสร้างขึ้นมาในปี 2018 จาก Social People
                <br/><br/>

                ใครที่สามารถนำข้อมูลใน Social Media มาวิเคราะห์ได้ จะช่วยให้เราเข้าใจลูกค้า
                หาโอกาสทางธุรกิจ ก้าวล้ำนำคู่แข่ง
                <br/><br/>

                <strong>วัน เวลา</strong><br/>
                - วันอังคารที่ 27 สิงหาคม 2562<br/>
                - เวลา 12.30 - 16.00 น.<br/><br/>

                สถานที่ Wisesight ตึก Suntowers ชั้น 33 โซน B เดินทาง MRT (ลงสถานีจตุจักร)<br/>
                สมัครได้ตั้งแต่วันนี้ - 20 สิงหาคม 2562 <br>
                ตามขั้นตอนง่าย ๆ ดังนี้ <br>
                1.) กดเข้าไปที่ line@ : <a href="http://nav.cx/g05ap0M">@Achieveplus</a> <br>
                2.) ขอฟอร์มการสมัครการเข้าร่วมกิจกรรม Company Visit จากสตาฟ <br>
                3.) กรอกฟอร์มการสมัคร <br>
                4.) รอการแจ้งผลการคัดเลือกจากทีมงาน
                <br/><br/>

                <strong>ด่วน ! รับสมัคร Achieve Member เพียง 60 ที่เท่านั้น <br>
                  ประกาศผลผ่านทางอีเมลที่ท่านลงทะเบียน
                </strong><br/><br>

                ไว้พบกันค่ะ <br>
                #AchievePluscommunity #BlendedLearning #Bettertogether #PathwaytoAchieve
                <br><br>

                <strong>หมายเหตุ</strong><br/>
                - กระบวนการคัดเลือกจากคณะกรรมการถือเป็นที่สิ้นสุด<br/>
                - ผู้ที่เคยเรียน Pathway จาก CHULA MOOC Achieve สามารถสมัคร Achieve Member ได้ที่นี่ <a href="https://cuma.page.link/nqXN">คลิก</a> <br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-7.fna.fbcdn.net/v/t1.0-9/54519676_381629692657392_1751208198376783872_o.jpg?_nc_cat=109&_nc_ht=scontent.fbkk2-7.fna&oh=3a0b6dbba2b50fc431ab885d1419fdf5&oe=5D60AA48"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-8.fna.fbcdn.net/v/t1.0-9/55738268_381629762657385_3030068473498697728_o.jpg?_nc_cat=103&_nc_ht=scontent.fbkk2-8.fna&oh=20275489211ebb20123c7668bbd8b4d7&oe=5D515CBB">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-8.fna.fbcdn.net/v/t1.0-9/55510755_381629702657391_5934219896131944448_o.jpg?_nc_cat=100&_nc_ht=scontent.fbkk2-8.fna&oh=4d646deb48286776d47811c5e63ef83d&oe=5D640FA7">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-7.fna.fbcdn.net/v/t1.0-9/54521997_381629685990726_443503476945715200_o.jpg?_nc_cat=101&_nc_ht=scontent.fbkk2-7.fna&oh=cf35835d11a035cd2aa604156488e07e&oe=5D56776F">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

              </v-row> -->
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> register now </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> register now </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <!-- <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img> -->
                      <v-icon>{{ salebox.icon }}</v-icon>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                  <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                  <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                    <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      width="30px">
                    </v-img>
                  </v-col>
                  <v-col cols="10" sm="10" md="4">
                    <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                  </v-col> -->
                </v-row>
                <!-- <br/> -->
                <!-- <v-row dense no-gutters >
                  <v-col cols="2">
                    <br/>
                    <v-row justify="center">
                      <v-img
                        :src="require('@/assets/image/icon-14.svg')"
                        max-width="30px">
                      </v-img>
                    </v-row>
                      <v-icon>fas fa-chalkboard-teacher</v-icon>
                  </v-col>
                  <v-col class="text-content">
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                  </v-col>
                </v-row> -->
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'ws0010001',
      workshop: [
        { title: '[Pathway to Achieve] : Achieve x Wisesight', date: '27/08/2562' },
      ],
      title: '[Pathway to Achieve] : Achieve x Wisesight',
      date: '27/08/2562',
      price: 'ฟรี',
      salebox: [
        { title: 'เยี่ยมชมสถานที่', icon: 'fas fa-laptop-code', id: 1 },
        { title: 'วันอังคารที่ 27 สิงหาคม 2562', icon: 'fas fa-calendar-alt', id: 2 },
        { title: '12.30 - 16.00 น.', icon: 'far fa-clock', id: 3 },
        { title: 'Wisesight ตึก Suntowers ชั้น 33 โซน B เดินทาง MRT (ลงสถานีจตุจักร)', icon: 'fas fa-map-marker-alt', id: 4 },
      ],
      isSale: new Date('2019-08-26T09:00:00.000Z') > new Date(),
      preInstructor: '',
      instructor: '',
      department: '',
      faculty: '',
      university: '',
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
